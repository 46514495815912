import React from 'react';

import { Container } from 'react-bootstrap';
import { Link } from "react-router-dom";
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../../Assets/logo11.jpg';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function Header() {

    return (

        <>

            <Navbar expand="lg" bg="white" variant="white" className='sticky-top  p-0' >
                <Container fluid className='px-5 py-0'>
                    <Navbar.Brand href="/" className='fw-bold fs-3 '>
                        <img
                            src={logo}
                            width="100"
                            height="100"
                            className="d-inline-block align-top rounded   "
                            alt="logo"
                        />

                    </Navbar.Brand>

                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                        <Nav className='transparent-header'>

                            <Nav.Link as={Link} to="/" className='text-uppercase text-center mx-2 fw-bold text-theme'>Home</Nav.Link>

                            <Nav.Link as={Link} to="/About" className='text-uppercase text-center mx-2 fw-bold'>About Us</Nav.Link>

                            <NavDropdown title="TOURS" id="basic-nav-dropdown" className='text-center fw-bold'>

                                <NavDropdown.Item as={Link} to="/southafrica" >South Africa</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/london" >London</NavDropdown.Item>
                                <NavDropdown.Item as={Link} to="/malaysia" >Malaysia</NavDropdown.Item>
                            </NavDropdown>
                            {/* <NavDropdown title="PRODUCTS" id="basic-nav-dropdown" className='text-center fw-bold'>
                            <NavDropdown.Item as={Link} to="/" >1</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" >2</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" >3</NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="SERVICES" id="basic-nav-dropdown" className='text-center fw-bold'>
                            <NavDropdown.Item as={Link} to="/" >1</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" >2</NavDropdown.Item>
                            <NavDropdown.Item as={Link} to="/" >3</NavDropdown.Item>
                            </NavDropdown> */}
                            <Nav.Link as={Link} to="/gallery" className='text-uppercase text-center mx-2 fw-bold'>Gallery</Nav.Link>
                            <Nav.Link as={Link} to="/videos" className='text-uppercase text-center mx-2 fw-bold'>Videos</Nav.Link>

                            {/* <Nav.Link as={Link} to="/ourteam" className='text-uppercase text-center mx-2 fw-bold'>Our Team</Nav.Link> */}
                            <Nav.Link as={Link} to="/technology" className='text-uppercase text-center mx-2 fw-bold'>Technology</Nav.Link>
                            <Nav.Link as={Link} to="/Contact" className='text-uppercase text-center mx-2 fw-bold'>Contact</Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        </>
    )

}

export default Header;