import React from 'react';

import { Container } from 'react-bootstrap';

import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
function Footer() {

  return (

    <>

      <footer className='bg-white'>
        <Container fluid >
          <Row className='p-3 text-center text-dark'>

          <Col lg={4}>
              <h6 className='py-3 px-3'>Menu</h6>
              <div className=' py-2 px-3'><Link to="/" className='text-muted text-decoration-none '>Home</Link></div>
              <div className=' py-2 px-3'><Link to="/About" className='text-muted text-decoration-none '>About</Link></div>


            </Col>
            <Col lg={4}>
              <h6 className='py-3 px-3'>COMPANY</h6>
              <div className=' py-2 px-3'><Link to="/Contact" className='text-muted text-decoration-none '>Contact</Link></div>
              <div className=' py-2 px-3'><Link to="/PrivacyPolicy" className=' text-decoration-none text-muted'>Privacy Policy</Link></div>
            </Col>
           
            <Col lg={4}>
              <h6 className='py-3 px-3'>QUICK LINKS</h6>
              <div className='py-2 px-3'><Link to="/Ourteam" className='lightTxt text-decoration-none text-muted'>Our Team</Link></div>
              <div className='py-2 px-3 text-grey'><Link to="/technology" className='lightTxt text-decoration-none text-muted'>Technology</Link></div>

            </Col>

          </Row>

          {/* <Row>
            <Col >
              <h5 className='text-center p-2'>Follow Us</h5>
              <div className='d-flex justify-content-between justify-content-sm-center my-4'>
                <a href="#" target="_blank"><i className="fa-brands fa-youtube fs-1 mx-2 text-danger rotate"></i></a>
                <a href="#" target="_blank"><i className="fa-brands fa-facebook fs-1 mx-2 text-info rotate"></i></a>
                <a href="#" target="_blank"><i className="fa-brands fa-instagram fs-1 mx-2 text-danger rotate"></i></a>

                <a href="#" target="_blank"><i className="fa-brands fa-linkedin fs-1 mx-2 text-primary rotate"></i></a>
              </div>
            </Col>
          </Row> */}


          <Row>
            <Col className='text-center p-2 text-muted'>
              <span >@{new Date().getFullYear()} www.santoshsportscricketacademy.com
              </span>
            </Col>
          </Row>
        </Container>
      </footer>

    </>
  )

}

export default Footer;