import { Container } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';

import p1 from '../../Assets/new/a1.jpg';
import p2 from '../../Assets/new/a2.jpg';
import p3 from '../../Assets/new/a3.jpg';
import p4 from '../../Assets/new/a4.jpg';
import p5 from '../../Assets/new/a5.jpg';
import p6 from '../../Assets/new/a6.jpg';
import p7 from '../../Assets/new/a7.jpg';
import p8 from '../../Assets/new/a8.jpg';
import p9 from '../../Assets/new/a9.jpg';
import p10 from '../../Assets/new/a10.jpg';
import p11 from '../../Assets/new/a11.jpg';
import p12 from '../../Assets/new/a12.jpg';
import p13 from '../../Assets/new/a14.jpg';
import p14 from '../../Assets/new/a15.jpg';
import p15 from '../../Assets/new/a16.jpg';
import p16 from '../../Assets/new/a17.jpg';
import p17 from '../../Assets/new/a18.jpg';
import p18 from '../../Assets/new/a19.jpg';
import p19 from '../../Assets/new/a20.jpg';
import p20 from '../../Assets/new/a21.jpg';
import p21 from '../../Assets/new/a22.jpg';
import p22 from '../../Assets/new/a90.jpg';
import p23 from '../../Assets/new/a93.jpg';
import p24 from '../../Assets/new/a25.jpg';
import p25 from '../../Assets/new/a26.jpg';
import p26 from '../../Assets/new/a27.jpg';
import p27 from '../../Assets/new/a28.jpg';
import p28 from '../../Assets/new/a29.jpg';
import p29 from '../../Assets/new/a30.jpg';
import p30 from '../../Assets/new/a31.jpg';
import p31 from '../../Assets/new/a32.jpg';
import p32 from '../../Assets/new/a33.jpg';
import p33 from '../../Assets/new/a34.jpg';
import p34 from '../../Assets/new/a35.jpg';
import p35 from '../../Assets/new/a36.jpg';
import p36 from '../../Assets/new/a37.jpg';
import p37 from '../../Assets/new/a38.jpg';
import p38 from '../../Assets/new/a39.jpg';
import p39 from '../../Assets/new/a40.jpg';
import p40 from '../../Assets/new/a41.jpg';
import p41 from '../../Assets/new/a42.jpg';
import p42 from '../../Assets/new/a43.jpg';
import p43 from '../../Assets/new/a44.jpg';
import p44 from '../../Assets/new/a45.jpg';
import p45 from '../../Assets/new/a46.jpg';
import p46 from '../../Assets/new/a47.jpg';
import p47 from '../../Assets/new/a48.jpg';
import p48 from '../../Assets/new/a49.jpg';
import p49 from '../../Assets/new/a50.jpg';
import p50 from '../../Assets/new/a51.jpg';
import p51 from '../../Assets/new/a52.jpg';
import p52 from '../../Assets/new/a53.jpg';
import p53 from '../../Assets/new/a54.jpg';
import p54 from '../../Assets/new/a55.jpg';
import p55 from '../../Assets/new/a56.jpg';
import p56 from '../../Assets/new/a57.jpg';
import p57 from '../../Assets/new/a58.jpg';
import p58 from '../../Assets/new/a59.jpg';
import p59 from '../../Assets/new/a60.jpg';
import p60 from '../../Assets/new/a61.jpg';
import p61 from '../../Assets/new/a62.jpg';
import p62 from '../../Assets/new/a63.jpg';
import p63 from '../../Assets/new/a64.jpg';
import p64 from '../../Assets/new/a65.jpg';
import p65 from '../../Assets/new/a13.jpg';
import p66 from '../../Assets/new/a13.jpg';
import p67 from '../../Assets/new/a13.jpg';
import p68 from '../../Assets/new/a13.jpg';
import p69 from '../../Assets/new/a13.jpg';
import p70 from '../../Assets/new/a13.jpg';
import p71 from '../../Assets/new/a13.jpg';
import p72 from '../../Assets/new/a13.jpg';
import p73 from '../../Assets/new/a13.jpg';
import p74 from '../../Assets/new/a13.jpg';
import p75 from '../../Assets/new/a13.jpg';
import p76 from '../../Assets/new/a13.jpg';
import p77 from '../../Assets/new/a13.jpg';
import p78 from '../../Assets/new/a13.jpg';
import p79 from '../../Assets/new/a13.jpg';
import p80 from '../../Assets/new/a13.jpg';
import p81 from '../../Assets/new/a13.jpg';
import p82 from '../../Assets/new/a13.jpg';
import p83 from '../../Assets/new/a13.jpg';
import p84 from '../../Assets/new/a13.jpg';
import p85 from '../../Assets/new/a13.jpg';
import p86 from '../../Assets/new/a86.jpg';
import p87 from '../../Assets/new/a87.jpg';
import p88 from '../../Assets/new/a88.jpg';
import p89 from '../../Assets/new/a89.jpg';
import p90 from '../../Assets/new/a90.jpg';
import p91 from '../../Assets/new/a91.jpg';
import p92 from '../../Assets/new/a94.jpg';



import g1 from '../../Assets/gallery/g1.jpg';
import g2 from '../../Assets/gallery/g2.jpg';
import g3 from '../../Assets/gallery/g3.jpg';
import g4 from '../../Assets/gallery/g4.jpg';
import g5 from '../../Assets/gallery/g5.jpg';
import g6 from '../../Assets/gallery/g6.jpg';
import g7 from '../../Assets/gallery/g7.jpg';
import g8 from '../../Assets/gallery/g8.jpg';
import g9 from '../../Assets/gallery/g9.jpg';
import g10 from '../../Assets/gallery/g10.jpg';
import k1 from '../../Assets/gallery/k1.jpg';
import k2 from '../../Assets/gallery/k2.jpg';
import k3 from '../../Assets/gallery/k3.jpg';
import k4 from '../../Assets/gallery/k4.jpg';
import k5 from '../../Assets/gallery/k5.jpg';
import k6 from '../../Assets/gallery/k6.jpg';
import k7 from '../../Assets/gallery/k7.jpg';
import k8 from '../../Assets/gallery/k8.jpg';
import k9 from '../../Assets/gallery/k9.jpg';
import k10 from '../../Assets/gallery/k10.jpg';
import k11 from '../../Assets/gallery/k11.jpg';



function Gallery(props) {
    const [galleryData, setGalleryData] = useState();
    useEffect(() => {
        setGalleryData(props.galleryData);
    }, [props.galleryData]);

    var img = [{ imgSrc: p42 }, { imgSrc: p44 }, { imgSrc: p43 }, { imgSrc: p1 }, { imgSrc: p2 }, { imgSrc: p3 }, { imgSrc: p4 }, { imgSrc: p5 }, { imgSrc: p6 }, { imgSrc: p7 }, { imgSrc: p8 }, { imgSrc: p9 }, { imgSrc: p10 }, { imgSrc: p11 }, { imgSrc: p17 }, { imgSrc: p13 }, { imgSrc: p20 }, { imgSrc: p21 }, { imgSrc: p16 }, { imgSrc: p12 }, { imgSrc: p18 }, { imgSrc: p20 }, { imgSrc: p21 }, { imgSrc: p22 }, { imgSrc: p23 }, { imgSrc: p24 }, { imgSrc: p25 }, { imgSrc: p26 }, { imgSrc: p27 }, { imgSrc: p28 }, { imgSrc: p29 }, { imgSrc: p30 }, { imgSrc: p31 }, { imgSrc: p32 }, { imgSrc: p33 }, { imgSrc: p34 }, { imgSrc: p35 }, { imgSrc: p36 }, { imgSrc: p37 }, { imgSrc: p38 }, { imgSrc: p39 }, { imgSrc: p40 }, { imgSrc: p46 }, { imgSrc: p47 }, { imgSrc: p48 }, { imgSrc: p49 }, { imgSrc: p50 }, { imgSrc: p51 }, { imgSrc: p52 }, { imgSrc: p53 }, { imgSrc: p54 }, { imgSrc: p55 }, { imgSrc: p56 }, { imgSrc: p57 }, { imgSrc: p58 }, { imgSrc: p59 }, { imgSrc: p60 }, { imgSrc: p61 }, { imgSrc: p62 }, { imgSrc: p63 }, { imgSrc: p64 }, { imgSrc: g1 }, { imgSrc: g2 }, { imgSrc: g3 }, { imgSrc: g4 }, { imgSrc: g5 }, { imgSrc: g6 }, { imgSrc: g7 }, { imgSrc: g8 }, { imgSrc: g9 }, { imgSrc: g10 }, { imgSrc: g2 }, { imgSrc: k1 }, { imgSrc: k2 }, { imgSrc: k3 }, { imgSrc: k4 }, { imgSrc: k6 }, { imgSrc: k1 }, { imgSrc: k8 }, { imgSrc: k9 }, { imgSrc: k10 }, { imgSrc: k11}, { imgSrc: k7 }]

    return (
        <>
            <Container fluid>
                <Row className='headingimg'>
                    <Col className='m-5'>
                        <h1 className='text-white m-4 mx-4 px-2 fw-bold text-center'>
                            Gallery</h1>
                        <div className='text-white m-4 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i class="fa-solid fa-circle-arrow-right fa-beat"></i><a className='text-white text-decoration-none m-3'>
                            Gallery</a></div>
                    </Col>
                </Row>
                <Row className='p-3'>
                    {img.map((images, index) => {
                        return (<Col key={index} sm={6} lg={4} className='p-3'>
                            <Card>
                                <Card.Img variant="top" src={images.imgSrc} className='img-fluid rounded zoom-img' />

                            </Card>
                        </Col>)
                    })}
                    <hr />
                    {
                        galleryData && galleryData.map((elem, ind) => {
                            return (
                                <Col key={ind} sm={6} lg={4} className='p-3'>
                                    <Card>
                                        <Card.Img variant="top" src={elem.image} className='img-fluid rounded zoom-img' />

                                    </Card>

                                </Col>)
                        })}

                </Row>
            </Container>

        </>
    )

}

export default Gallery;


