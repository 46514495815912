import React from "react";
import img1 from "../../Assets/T1.jpg";
import img2 from "../../Assets/T2.jpg";
import img3 from "../../Assets/T3.jpg";
import img4 from "../../Assets/T4.jpg";

import img8 from "../../Assets/T5.jpg";
// import img9 from "../../Assets/logo11.jpg";
// import img10 from "../../Assets/logo11.jpg";
// import img11 from "../../Assets/logo11.jpg";


import Carousel from 'react-bootstrap/Carousel';

function CardSlider() {
    return (
        <>
     

            <Carousel variant="light" className="pb-4 m-2">
              
                <Carousel.Item className="p-lg-4">
                    <div className="px-5 mx-5 text-center">
                        <img variant="top" src={img1} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid" alt="img"/>
                        <img variant="top" src={img2} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid" alt="img"/>
                        <img variant="top" src={img3} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid" alt="img"/>
                        <img variant="top" src={img4} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid"alt="img" />
                        <img variant="top" src={img8} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid" alt="img"/>

                    </div>
                </Carousel.Item>
                <Carousel.Item className="p-lg-4">
                    <div className="px-5 mx-5 text-center">
                        <img variant="top" src={img8} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid" alt="img"/>
                        <img variant="top" src={img1} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid " alt="img"/>
                        <img variant="top" src={img4} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid"alt="img" />
                        <img variant="top" src={img2} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid" alt="img"/>
                        <img variant="top" src={img3} style={{ width: '150px' }} className="mx-auto px-2 pt-3 img-fluid" alt="img"/>

                    </div>
                </Carousel.Item>
               
              
            </Carousel>
        </>)

}

export default CardSlider;