import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
function PrivacyPolicy() {

    return (

        <>


            <Container fluid className=''>
                <Row className='headingimg'>
                    <Col className='m-4'>
                        <h1 className='text-white m-5 px-2 fw-bold text-center'>Privacy Policy</h1>
                        <div className='text-white m-5 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-white text-decoration-none m-3'>Privacy Policy</a></div>
                    </Col>

                </Row>
                <Row className=''>


                    <Col className='m-3'>

                        <h1 className='text-center p-2'>Privacy Policy</h1>
                        <p className='text-grey'>Santosh Sports Cricket Academy respects the privacy of its Users and is committed to protect it in all respects. With a view to offer an enriching and holistic internet experience to its Users, Santosh Sports Cricket Academy offers a vast repository of Amusement Facilities. Most of the Amusement Facilities are offered for free The information about the User is collected by Santosh Sports Cricket Academy as (i) information supplied by Users and (ii) information automatically tracked during User's navigation on Santosh Sports Cricket Academy website.</p>
                        <p className='text-grey'>Before you submit any information to the Portal, please read this Privacy Policy for an explanation of how we will treat your personal information. By using any part of the Portal, you consent to the collection, use, disclosure and transfer of your personal information for the purposes outlined in this Privacy Policy and to the collection, processing and maintenance of this information. If you do not agree to this Privacy Policy, please do not use the Portal. Your use of any part of the Portal indicates your acceptance of this Privacy Policy and of the collection, use and disclosure of your personal information in accordance with this Privacy Policy.

                        </p>
                        <p className='text-grey'>While you have the option not to provide us with certain information or withdraw consent to collect certain information, kindly note that in such an event you may not be able to take full advantage of the entire scope of features and services offered to you and we reserve the right not to provide you with our services.</p>

                    </Col>

                </Row>
            </ Container >


        </>
    )

}

export default PrivacyPolicy;