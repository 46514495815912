import { Container } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import g1 from '../../Assets/videos/v2.mp4';
import g2 from '../../Assets/videos/v3.mp4';
import g3 from '../../Assets/videos/v4.mp4';
import g4 from '../../Assets/videos/v5.mp4';
import g5 from '../../Assets/videos/v6.mp4';
import g6 from '../../Assets/videos/v7.mp4';
import g7 from '../../Assets/videos/v8.mp4';
import g8 from '../../Assets/videos/v9.mp4';
import g9 from '../../Assets/videos/v10.mp4';
import g10 from '../../Assets/videos/v11.mp4';
import g11 from '../../Assets/videos/v12.mp4';
import g12 from '../../Assets/videos/v13.mp4';
import g13 from '../../Assets/videos/v14.mp4';
import g14 from '../../Assets/videos/v15.mp4';
import g15 from '../../Assets/videos/v16.mp4';
import g16 from '../../Assets/videos/v17.mp4';
import g17 from '../../Assets/videos/v18.mp4';
import g18 from '../../Assets/videos/v19.mp4';
import g19 from '../../Assets/videos/v20.mp4';
import g20 from '../../Assets/videos/v21.mp4';
import g21 from '../../Assets/videos/v22.mp4';
import g22 from '../../Assets/videos/v23.mp4';
import g23 from '../../Assets/videos/v24.mp4';
import g24 from '../../Assets/videos/v25.mp4';
import g25 from '../../Assets/videos/v26.mp4';
import g26 from '../../Assets/videos/v27.mp4';
import g27 from '../../Assets/videos/v28.mp4';
import g28 from '../../Assets/videos/v29.mp4';
import g29 from '../../Assets/videos/v30.mp4';
import g30 from '../../Assets/videos/v31.mp4';
import g31 from '../../Assets/videos/v32.mp4';
import g32 from '../../Assets/videos/v33.mp4';
import g33 from '../../Assets/videos/v34.mp4';
import g34 from '../../Assets/videos/v35.mp4';
import g35 from '../../Assets/videos/v36.mp4';
import g36 from '../../Assets/videos/v37.mp4';
import g37 from '../../Assets/videos/v38.mp4';
import g38 from '../../Assets/videos/v39.mp4';
import g39 from '../../Assets/videos/v40.mp4';
import g40 from '../../Assets/videos/v41.mp4';
import g41 from '../../Assets/videos/v42.mp4';
import g42 from '../../Assets/videos/v43.mp4';
import g43 from '../../Assets/videos/v44.mp4';
import g44 from '../../Assets/videos/v45.mp4';
import g45 from '../../Assets/videos/v46.mp4';
import g46 from '../../Assets/videos/v47.mp4';
import g47 from '../../Assets/videos/v48.mp4';
import g48 from '../../Assets/videos/v49.mp4';



function Videos(props) {
    const [galleryData, setGalleryData] = useState();
    useEffect(() => {
        setGalleryData(props.galleryData);
    }, [props.galleryData]);

    var img = [{ imgSrc: g1 }, { imgSrc: g2 }, { imgSrc: g3 }, { imgSrc: g4 }, { imgSrc: g5 }, { imgSrc: g6 }, { imgSrc: g7 }, { imgSrc: g8 }, { imgSrc: g9 }, { imgSrc: g10 }, { imgSrc: g11 }, { imgSrc: g12 }, { imgSrc: g13 }, { imgSrc: g14 }, { imgSrc: g15 }, { imgSrc: g16 }, { imgSrc: g17 }, { imgSrc: g18 }, { imgSrc: g19 }, { imgSrc: g20 }, { imgSrc: g21 }, { imgSrc: g22 }, { imgSrc: g23 }, { imgSrc: g24 }, { imgSrc: g25 }, { imgSrc: g26 }, { imgSrc: g27 }, { imgSrc: g28 }, { imgSrc: g29 }, { imgSrc: g30 }, { imgSrc: g31 }, { imgSrc: g32 }, { imgSrc: g33 }, { imgSrc: g34 }, { imgSrc: g35 }, { imgSrc: g36 }, { imgSrc: g37 }, { imgSrc: g38 }, { imgSrc: g39 }, { imgSrc: g40 }, { imgSrc: g41 }, { imgSrc: g42 }, { imgSrc: g43 }, { imgSrc: g44 }, { imgSrc: g45 }, { imgSrc: g46 }, { imgSrc: g47 }, { imgSrc: g48 }]

    return (
        <>
            <Container fluid>
                <Row className='headingimg'>
                    <Col className='m-5'>
                        <h1 className='text-white m-4 mx-4 px-2 fw-bold text-center'>
                        Videos</h1>
                        <div className='text-white m-4 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i class="fa-solid fa-circle-arrow-right fa-beat"></i><a className='text-white text-decoration-none m-3'>
                            Videos</a></div>
                    </Col>
                </Row>
                <Row className='p-3'>
                    {img.map((images, index) => {
                        return (<Col key={index} sm={6} lg={4} className='p-3'>
                            <Card>
                                <video  controls>
  <source src={images.imgSrc} type="video/mp4" />
  Your browser does not support the video tag.
</video>
                            </Card>
                        </Col>)
                    })}
                    <hr />
                    {
                        galleryData && galleryData.map((elem, ind) => {
                            return (
                                <Col key={ind} sm={6} lg={4} className='p-3'>
                                    <Card>
                                        <Card.Img variant="top" src={elem.image} className='img-fluid rounded zoom-img' />

                                    </Card>

                                </Col>)
                        })}

                </Row>
            </Container>

        </>
    )

}

export default Videos;


