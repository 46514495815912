
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Tec1 from '../../Assets/tec1.jpg';
import Tec2 from '../../Assets/tec2.jpg';
import Tec3 from '../../Assets/tec3.jpg';

function Technology() {
    return(
<>
<Container fluid>
<Row className='headingimg p-4'>
                    <Col className='m-4'>
                        <h1 className='text-dark m-5 px-2 fw-bold text-start'>Technology</h1>
                        <div className='text-dark m-5 fw-bold text-start'><a href='/' className=' text-dark m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-dark text-decoration-none m-3'>Contact Us</a></div>
                    </Col>
                 

                </Row>
                <Row className='m-3'>
                    <Col className='p-3'>
                        <h3>ADVANCED BOWLING MACHINE
 </h3>
                        <p>Santosh Sports Cricket Academy has advanced bowling machine for cricket players. The machine is capable of bowling with a speed of 60 km/hour to 165 km/hour. The machine could be used for various types of bowling such as spin, off-spin, leg-break and so on. </p>
                    
                    </Col>
                </Row>
                <Row className='m-3'> 
                    <Col lg={4} className='text-center'>
                    <img src={Tec1} alt='img1' className='img-fluid p-3 shadow'/>
                    </Col>
                    <Col lg={4} className='text-center'>
                    <img src={Tec2} alt='img1' className='img-fluid p-3 shadow'/>
                    </Col>
                    <Col lg={4} className='text-center'>
                    <img src={Tec3} alt='img1' className='img-fluid p-3 shadow'/>
                    </Col>
                </Row>
                <Row className='m-3'>
                    <Col className='p-3'>
                    <h4> VIDEO ANALYSIS</h4>
                    <p>Video analysis plays vital role in developing your technique, which is world wide used. Its’ one of the most important for the coach which allows him to do a detailed analysis of what is going on within someone’s action or shot. </p>
                    <p>At Santosh Sports Cricket Academy, a video of every player is taken and detail explaination on how to change the approach to the particular shot,
delivery or throw is given to individual player. </p>

                    </Col>
                </Row>
                </Container>

</>    
    )
    
    }
    
    export default Technology;