
import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
// import natraj from '../../Assets/natraj.jpg';
// import apsara from '../../Assets/apsara.jpg';
import omex from '../../Assets/spp.jpg';

function Sponsors() {
    return(
<>
<Container >
    <Row className=''>
        <h2 className='text-center pt-2'>SPONSORS</h2>
        <h3 className='text-center pt-2'>Omtex PVT. LTD.</h3>
        <Col lg={12} className='text-center'> 
            <img src={omex} alt='img' className='img-fluid'/>
        </Col>
        {/* <Col lg={6} className='text-center'>
            <img src={apsara} alt='img' className='img-fluid p-3'/>
        </Col> */}
    </Row>

</Container>


</>    
    )
    
    }
    
    export default Sponsors;