import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Ab1 from '../../Assets/ab1.jpg';
import Van from '../../Assets/van.jpg';
import Santoshsir from '../../Assets/gallery/t2.jpg'
function About() {

    return (

        <>
            <Container fluid className=''>

                <Row className='headingimg p-4'>
                    <Col className='m-4'>
                        <h1 className='text-dark m-5 px-2 fw-bold text-start'>Learn More About Us</h1>
                        <div className='text-dark m-5 fw-bold text-start'><a href='/' className=' text-dark m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-dark text-decoration-none m-3'>About Us</a></div>
                    </Col>

                </Row>
                <Row>
                    <Col lg={5} className='text-center'>
                        <img src={Santoshsir} alt='img1' className='img-fluid p-3 shadow rounded m-2' />
                    </Col>
                    <Col className='pt-3  textJustify' lg={7}>
                        <p >
                            The Santosh Sports Cricket Academy is one of the leading Cricket Academy in Kalyan.The Academy is registered with the objectives to groom boys and girls of kalyan to be budding cricketers.The Santosh Sports Cricket Academy is a Kalyan-based cricket facility offering year round cricket coaching in an enjoyable, safe and friendly environment. At the academy, we offer cricket coaching for children and adults of all ages and levels of experience from complete beginners to seniors and professional cricketers and Our Academy arrange the free Coaching for Girls .
                        </p>
                        <p >
                            we can offer all weather coaching and facilities, 50 weeks a year, seven days a week from 7am to 10pm and 4pm to 6.30pm.

                            In addition to coaching for both groups and individuals, we offer net and equipment hire, school holiday camps, master classes, international cricket tours, an onsite and online cricket shop,  and much more….

                            All our coaches are Government qualified and first class cricketers. The Santosh Sports Cricket Academy (SSCA) team’s aim is to provide a coaching experience which focuses on developing talent, enhancing life skills and improving fitness.

                            We want every person that comes to Santosh Sports Cricket Academy (SSCA) to have a positive experience, feel welcome and part of our ‘cricketing family’, and most importantly to enjoy the game!

                            This website should tell you everything you need to know about Santosh Sports Cricket Academy (SSCA) and what coaching is right for your child or yourself.

                            If you prefer just call and speak to us: 9870429041/8898947374 or email us on: santoshpthk80@gmail.com.
                        </p>
                    </Col>


                </Row>
                <Row>

                    <Col className='text-center' lg={6}>
                        <img src={Ab1} alt='img1' className='img-fluid p-3 shadow rounded m-2' />
                        </Col>
                        <Col className='text-center' lg={6}>
                        <h2 className='text-center'>Pickup & Drop Facility</h2>

                        <img src={Van} alt='img1' className='img-fluid p-3 shadow rounded m-2 ' />

                    </Col>
                </Row>

            </ Container >



        </>
    )

}

export default About;