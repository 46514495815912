import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Cimg1 from '../../Assets/gallery/t1.jpg';
import Cimg2 from '../../Assets/gallery/t2.jpg';
import Cimg3 from '../../Assets/gallery/t3.jpg';
import Cimg4 from '../../Assets/gallery/t4.jpg';
import Cimg5 from '../../Assets/gallery/t5.jpg';
import Cimg6 from '../../Assets/gallery/t6.jpg';


function OurTeam() {
    return (
        <>
            <Container fluid className=''>
            <Row className='headingimg p-4'>
                    <Col className='m-4'>
                        <h1 className='text-dark m-5 px-2 fw-bold text-start'>Our Team</h1>
                        <div className='text-dark m-5 fw-bold text-start'><a href='/' className=' text-dark m-3 text-decoration-none'>Home</a><i className="fa-solid fa-circle-arrow-right "></i><a className='text-dark text-decoration-none m-3'>Our Team</a></div>
                    </Col>
                 

                </Row>
                <Row>
                    <h2 className='text-center p-3'>Our Team</h2>


                    <Col className='p-3  d-flex justify-content-center justify-content-center' lg={4}>

                        <Card style={{ width: '18rem' }} className=' text-center '>
                            <Card.Img variant="top" src={Cimg1} />
                            <Card.Body className='thirdColor text-dark'>
                                <Card.Title>Mr. Brij Kishore Dutt</Card.Title>
                                <Card.Text>
                                    PRESIDENT
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>






                    <Col className='p-3  d-flex justify-content-center justify-content-center' lg={4}>

                        <Card style={{ width: '18rem' }} className='text-center '>
                            <Card.Img variant="top" src={Cimg2} />
                            <Card.Body className='thirdColor text-dark '>
                                <Card.Title>Mr. Santosh Pathak</Card.Title>
                                <Card.Text>
                                    Head Coach
                                </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>

                    <Col className='p-3  d-flex justify-content-center justify-content-center' lg={4}>



                        <Card style={{ width: '18rem' }} className=' text-center'>
                            <Card.Img variant="top" src={Cimg3} />
                            <Card.Body className='thirdColor text-dark'>
                                <Card.Title>Mr. Mahendra Deshmukh
                                </Card.Title>
                                <Card.Text>
                                    Sr. Coach

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className='p-3  d-flex justify-content-center justify-content-center' lg={4}>



                        <Card style={{ width: '18rem' }} className=' text-center '>
                            <Card.Img variant="top" src={Cimg4} />
                            <Card.Body className='thirdColor text-dark'>
                                <Card.Title>Mr. Paresh Hindurao</Card.Title>
                                <Card.Text>
                                    Coach             </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col className='p-3  d-flex justify-content-center justify-content-center' lg={4}>

                        <Card style={{ width: '18rem' }} className=' text-center '>
                            <Card.Img variant="top" src={Cimg5} />
                            <Card.Body className='thirdColor text-dark'>
                                <Card.Title>Mr. Rohit Dhumal</Card.Title>
                                <Card.Text>
                                    Coach
                                </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>





                    <Col className='p-3  d-flex justify-content-center justify-content-center' lg={4}>

                        <Card style={{ width: '18rem' }} className=' text-center '>
                            <Card.Img variant="top" src={Cimg6} />
                            <Card.Body className='thirdColor text-dark'>
                                <Card.Title>Mr. Mangesh Brid</Card.Title>
                                <Card.Text>
                                    Coach
                                </Card.Text>
                            </Card.Body>
                        </Card>


                    </Col>





                </Row>
            </Container>





        </>

    )

}

export default OurTeam;