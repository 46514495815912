import React from 'react';
import Slide from '../../Assets/sideimg.png'
import { Container } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import Bottom from '../../Assets/bottom.png';
import CardSlider from '../../Components/CardSlider';
// import Cards from '../../Components/Cards';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Sponsors from '../../Components/Sponsors';
import Marquee from '../../Components/Marquee';

function Home() {


   return (
      <>
         <Marquee />

         <Container fluid className='banner'>

            <Row className='p-5   d-flex align-items-center justify-content-center'>

               <Col className='p-3 ' sm={6}>
                  <p className='fs-1 fw-bold'>Where Every Innings
                     <br />
                     is an Adventure</p>
                  <p className='textJustify'>The Santosh Sports Cricket Academy is one of the leading Cricket Academy in Kalyan.The Academy is registered with the objectives to groom boys and girls of kalyan to be budding cricketers.The Santosh Sports Cricket Academy is a Kalyan-based cricket facility offering year round cricket coaching in an enjoyable, safe and friendly environment. At the academy, we offer cricket coaching for children and adults of all ages and levels of experience from complete beginners to seniors and professional cricketers and Our Academy arrange the free Coaching for Girls .</p>
                  <div className='py-3'>
                     <a href='#' className="btn-theme  text-decoration-none"> Join Now <i class="fa-solid fa-hand-pointer"></i></a>

                  </div>
               </Col>
               <Col className='p-3' sm={6}>
                  <img src={Slide} alt='img' className='img-fluid' />
               </Col>
            </Row>
            <Row className='p-0'>
               <img src={Bottom} alt='img' className='img-fluid p-0' />

            </Row>
         </Container>

         <CardSlider />
         {/* <Cards /> */}
         <Container fluid className='bg-theme'>

            <Row>
               <Col lg={6} >

                  <Form className='m-3  rounded-4' id="contact-form">


                     <Row className='m-3 p-3 '>
                        <h2 className='m-3'>Get in Touch with Us</h2>

                        <Col lg={12} >
                           <Form.Control type="text" placeholder="Enter Name" className='p-2 m-2' name="from_name" id="from_name" required />
                        </Col>
                        <Col lg={12} >
                           <Form.Control type="email" placeholder="Enter email" className='p-2 m-2' name="user_email" required />
                        </Col>
                        <Col lg={12}>
                           <Form.Control type="text" placeholder="Phone" className='p-2 m-2' name="contact_number" required />
                        </Col>
                        <Col lg={12}>
                           <Form.Control as="textarea" rows={3} className='p-2 m-2' name="message" id="message" required />
                        </Col>
                        <Col lg={12} >

                           <Button variant="success" type="submit" className='p-2 m-2 mb-4 fw-bold'>
                              <Form.Control type="submit" value="Send" />
                           </Button>


                        </Col>

                     </Row>
                  </Form>

               </Col>
               <Col lg={6} className="p-3 text-center">
                  <div className="mapouter m-3"><div className="gmap_canvas"><iframe className="gmap_iframe" width="100%" height='500' frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=santosh sports cricket academy Chanakya Nagar, Khadakpada, Kalyan, Maharashtra 421301, India&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe><a href="https://embed-googlemap.com"></a></div><style></style></div>

               </Col>
            </Row>

         </Container>
         <Sponsors />


      </>
   )

}

export default Home;