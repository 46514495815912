
import React, { useState, useEffect } from "react";
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import g1 from '../../Assets/london/l1.jpg';
import g2 from '../../Assets/london/l2.jpg';
import g3 from '../../Assets/london/l3.jpg';
import g4 from '../../Assets/london/l4.jpg';
import g5 from '../../Assets/london/l5.jpg';
import g6 from '../../Assets/london/l6.jpg';
import g7 from '../../Assets/london/l7.jpg';
import g8 from '../../Assets/london/l8.jpg';
import g9 from '../../Assets/london/l9.jpg';
import g10 from '../../Assets/london/l10.jpg';
function London(props) {
    const [galleryData, setGalleryData] = useState();
    useEffect(() => {
        setGalleryData(props.galleryData);
    }, [props.galleryData]);
    var img = [{ imgSrc: g1 },{ imgSrc: g2 }, { imgSrc: g3 }, { imgSrc: g4 }, { imgSrc: g5 }, { imgSrc: g6 }, { imgSrc: g7 }, { imgSrc: g8 }, { imgSrc: g9 }, { imgSrc: g10 },{ imgSrc: g2 }, { imgSrc: g3 }]

    return(
<>

<Container fluid>
                <Row className='headingimg'>
                    <Col className='m-5'>
                        <h1 className='text-white m-4 mx-4 px-2 fw-bold text-center'>LONDON TOUR</h1>
                        <div className='text-white m-4 fw-bold text-center'><a href='/' className=' text-white m-3 text-decoration-none'>Home</a><i class="fa-solid fa-circle-arrow-right fa-beat"></i><a className='text-white text-decoration-none m-3'>
                        LONDON TOUR</a></div>
                    </Col>
                </Row>
                <Row className='p-3'>
                    {img.map((images, index) => {
                        return (<Col key={index} sm={6} lg={4} className='p-3'>
                            <Card>
                                <Card.Img variant="top" src={images.imgSrc} className='img-fluid rounded zoom-img' />

                            </Card>
                        </Col>)
                    })}
                    <hr />
                    {
                        galleryData && galleryData.map((elem, ind) => {
                            return (
                                <Col key={ind} sm={6} lg={4} className='p-3'>
                                    <Card>
                                        <Card.Img variant="top" src={elem.image} className='img-fluid rounded zoom-img' />

                                    </Card>

                                </Col>)
                        })}

                </Row>
            </Container>
</>    
    )
    
    }
    
    export default London;