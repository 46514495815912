import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import Layout from './Pages/Layout';
import Home from "./Pages/Home";
import NoPage from "./Pages/NoPage";
import About from './Pages/About';
import Contact from './Pages/Contact';
import Gallery from './Pages/Gallery';
import OurTeam from './Pages/OurTeam';
import Technology from './Pages/Technology';
import SouthAfrica from './Pages/SouthAfrica';
import Malaysia from './Pages/Malaysia';
import London from './Pages/London';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Videos from './Pages/Videos';
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<About />} />
       
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/ourteam" element={<OurTeam />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/videos" element={<Videos />} />

          <Route path="/technology" element={<Technology />} />
          <Route path="/london" element={<London />} />
          <Route path="/malaysia" element={<Malaysia />} />
          <Route path="/southAfrica" element={<SouthAfrica />} />

  
          
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
