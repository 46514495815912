
import React from 'react';
function NoPage() {
    return(
        <h1>NoPage page</h1>
    
    )
    
    }
    
    export default NoPage;