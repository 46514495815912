import React from 'react';
import { Container } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import { Col } from 'react-bootstrap';
import Tours from '../../Assets/broucher1.pdf';

function marquee() {
  return (
    <>
      <Container fluid className=''>

        <Row>
          <Col>
          <p class="marquee">
   <span>
      <a href={Tours} target='_blank' className='text-decoration-none'>Click here for upcoming South Africa tour.</a>

   </span>
   </p>
          </Col>
        </Row>
      </Container>

    </>

  );
}

export default marquee;